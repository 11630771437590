import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { Link } from "@mui/material";

import HospitalIconSolid from "../assets/icons/HospitalIconSolid.svg";
import FirstAidkitIcon from "../assets/icons/FirstAidkitIcon.svg";
import HealthSheildIconSolid from "../assets/icons/HealthSheildIconSolid.svg";
import HeartIconSolid from "../assets/icons/HeartIconSolid.svg";
import MicroscopesolidIcon from "../assets/icons/MicroscopesolidIcon.svg";
import StethoscopeSolidIcon from "../assets/icons/StethoscopeSolidIcon.svg";

const iconStyles = {
  width: "60px",
  height: "auot",
};

const items = [
  {
    icon: <img src={HospitalIconSolid} alt="icon" style={iconStyles} />,
    title: "Preventing Disease",
    description:
      "Focuses on using cutting-edge AI to empower individuals to take control of their health.",
    id: "disease-prevention",
  },
  {
    icon: <img src={MicroscopesolidIcon} alt="icon" style={iconStyles} />,
    title: "Disease Prediction",
    description:
      "Harnesses the power of AI to predict your risk of developing various diseases.",
    id: "disease-prediction",
  },
  {
    icon: <img src={HeartIconSolid} alt="icon" style={iconStyles} />,
    title: "Early disease detection",
    description:
      "Utilizes cutting-edge AI to identify potential health issues in their earliest stages.",
    id: "early-disease-detection",
  },
  {
    icon: <img src={StethoscopeSolidIcon} alt="icon" style={iconStyles} />,
    title: "Accurate Diagnosis",
    description:
      "leverages the power of AI to revolutionize the diagnostic process. By analyzing your medical history, symptoms, and test results.",
    id: "accurate-diagnosis",
  },
  {
    icon: <img src={FirstAidkitIcon} alt="icon" style={iconStyles} />,
    title: "Personalized Treatment",
    description:
      "leverages the power of AI to create a personalized treatment plan designed specifically for you.",
    id: "personalized-treatment",
  },
  {
    icon: <img src={HealthSheildIconSolid} alt="icon" style={iconStyles} />,
    title: "Healthcare Cost Guardian",
    description:
      "leverages AI to empower you to take control of your healthcare expenses.",
    id: "healthcare-cost-guardian",
  },
];

export default function Highlights() {
  return (
    <Box
      id="features"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4" color={"text.primary"}>
            Our main focus
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Leveraging innovative technologies to advance healthcare by
            emphasizing disease prevention, early detection, accurate diagnosis,
            personalized treatment, and cost reduction.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  background: "transparent",
                  alignItems: "center",
                }}
              >
                <Box sx={{ color: "primary.main" }}>{item.icon}</Box>
                <Stack alignItems={"center"} spacing={1}>
                  <Typography fontWeight="medium" gutterBottom color={"gray"}>
                    {item.title}
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    variant="body2"
                    sx={{ color: "grey.400" }}
                  >
                    {item.description}
                  </Typography>
                  <Link
                    href={`blog/${item.id}`}
                    color="primary"
                    variant="body2"
                    fontWeight="bold"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      "& > svg": { transition: "0.2s" },
                      "&:hover > svg": { transform: "translateX(2px)" },
                      cursor: "pointer",
                    }}
                  >
                    <span>Learn more</span>
                    <ChevronRightRoundedIcon
                      fontSize="small"
                      sx={{ mt: "1px", ml: "2px" }}
                    />
                  </Link>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
