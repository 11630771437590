import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";

interface BlogContentProps {
  body?: string[];
}

export default function BlogContent({ body }: BlogContentProps) {
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "70%" },
          textAlign: { sm: "left", md: "left" },
        }}
      >
        {body?.map((text) => (
          <Typography component={'p'} variant="h6" color="text.primary" sx={{marginBottom: '40px'}}>
            {text}
          </Typography>
        ))}
      </Box>
    </Container>
  );
}
