import * as React from "react";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { IconButton, Stack } from "@mui/material";
import { ArrowBack, ArrowForward, Star } from "@mui/icons-material";

export default function Testimonials() {
  return (
    <Container
      id="howitworks"
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Stack
        sx={{
          width: { xs: "100%", sm: "90%" },
          alignItems: "center",
          px: "30px",
          justifyContent: { xs: "center", sm: "space-between" },
        }}
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
      >
        <Stack spacing={2} flex={1}>
          <Stack direction={"row"}>
            <Card sx={{ px: "30px", py: "5px", borderRadius: "999px" }}>
              <Typography variant="h6">Testimonials</Typography>
            </Card>
          </Stack>
          <Typography variant="h6">Our Testimonials</Typography>
          <Typography>
            There some customers who are satisfied with the place and services
            we have provided
          </Typography>
          <Stack direction={"row"} spacing={2}>
            <IconButton
              sx={{ bgcolor: "grey.400", width: "40px", height: "40px" }}
            >
              <ArrowBack style={{ fontSize: "20px" }} />
            </IconButton>
            <IconButton
              sx={{ bgcolor: "grey.400", width: "40px", height: "40px" }}
            >
              <ArrowForward style={{ fontSize: "20px" }} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack spacing={3} flex={2}>
          <Card
            sx={{
              py: "20px",
              px: "30px",
              borderRadius: "30px",
              bgcolor: "#F0FEF6",
            }}
          >
            <Stack spacing={3}>
              <Stack direction={"row"} spacing={2}>
                {[1, 2, 3, 4, 5].map((_, index) => (
                  <Star key={index} style={{ color: "#FFCE1F" }} />
                ))}
              </Stack>
              <Typography>
                I appreciate the attention to detail in the design of this
                product. The small touches make a big difference, and it's
                evident that the creators focused on delivering a premium
                experience.
              </Typography>
              <Stack direction={"row"} spacing={1}>
                <Avatar src="https://images.unsplash.com/photo-1570158268183-d296b2892211?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                <Stack>
                  <Typography>Emmanuel Amah</Typography>
                  <Typography>CEO, Livemore</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Container>
  );
}
