import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import heroImage from "../assets/images/doctor.png";

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #CEE5FD, #FFF)"
            : `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 4 },
        }}
      >
        <Stack
          sx={{ width: { xs: "100%", sm: "80%" } }}
          direction={{ xs: "column", md: "row" }}
          spacing={2}
        >
          <Stack spacing={2} useFlexGap sx={{ justifyContent: "center" }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: "clamp(3.5rem, 10vw, 4rem)",
              }}
            >
              The Future of&nbsp;
              <Typography
                component="span"
                variant="h1"
                sx={{
                  fontSize: "clamp(3rem, 10vw, 4rem)",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "primary.main"
                      : "primary.light",
                }}
              >
                Healthcare
              </Typography>
            </Typography>
            <Typography
              color="text.secondary"
              sx={{ width: { sm: "100%", md: "80%" } }}
            >
              Our commitment to pioneering innovations is revolutionizing the
              landscape of wellness
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
            >
              <Button variant="contained" color="primary">
                <a target="_blank" href="https://app.livemoreai.com">
                  Get started
                </a>
              </Button>
            </Stack>
          </Stack>
          <Box sx={{ alignSelf: "center" }}>
            <img
              src={heroImage}
              alt="Hero"
              style={{
                width: "350px",
                height: "auto",
              }}
            />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
