import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";
import { Button, Link, Stack } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const posts = [
  {
    title: "Revolutionize Your Healthcare with AI",
    image:
      "https://images.unsplash.com/photo-1530497610245-94d3c16cda28?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGhlYWx0aGNhcmV8ZW58MHx8MHx8fDA%3D",
    descriptions:
      "Discover how our cutting-edge healthcare AI system is transforming the industry. From predictive analytics to personalized treatment recommendations, unlock the power of data-driven healthcare for better patient outcomes.",
  },
  {
    title: "Unlocking the Future of Medicine",
    descriptions:
      "Join us in shaping the future of healthcare with our innovative AI platform. By harnessing the latest advancements in artificial intelligence and machine learning, we're empowering healthcare professionals to make more informed decisions, streamline workflows, and ultimately, save lives.",
    image:
      "https://images.unsplash.com/photo-1576765607924-3f7b8410a787?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGhlYWx0aGNhcmV8ZW58MHx8MHx8fDA%3D",
  },
  {
    title: "Empowering Healthcare Providers",
    image:
      "https://images.unsplash.com/photo-1434494878577-86c23bcb06b9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fG1lZGljYWwlMjBkZXZpY2VzfGVufDB8fDB8fHww",
    descriptions:
      "Experience the difference our healthcare AI system can make in your practice. With intuitive interfaces, real-time insights, and actionable intelligence, our platform seamlessly integrates into existing workflows, enabling healthcare providers to deliver higher quality care with greater efficiency.",
  },
];

export default function Blog() {
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Get more important information about health
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {posts.map((post, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                border: "0px solid",
                p: 0,
              }}
            >
              <CardContent>
                <img
                  src={post.image}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  alt={"doctor"}
                />
                <Typography
                  my={1}
                  variant="h6"
                  component={"p"}
                  color={"text.primary"}
                >
                  {post.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {post.descriptions}
                </Typography>
                <Link
                  color="primary"
                  variant="body2"
                  fontWeight="bold"
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    "& > svg": { transition: "0.2s" },
                    "&:hover > svg": { transform: "translateX(2px)" },
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <span>Learn more</span>
                  <ChevronRightRoundedIcon
                    fontSize="small"
                    sx={{ mt: "1px", ml: "2px" }}
                  />
                </Link>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pr: 2,
                }}
              ></Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Stack>
        <Button variant="contained" sx={{ px: "30px" }}>
          View all Blog
        </Button>
      </Stack>
    </Container>
  );
}
