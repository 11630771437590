import { Routes, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import BlogPage from "../pages/blog";

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="blog/:articleId" element={<BlogPage />} />
    </Routes>
  );
}
