import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";
import { Stack } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const featuresList = [
  "Personalized Health Assessment",
  "Continuous Health Monitoring",
  "Tailored Treatment Plans",
  "Empowering Individuals",
];

const Features = ({ title }: { title: string }) => (
  <Grid item xs={12} sm={6}>
    <Stack direction={"row"} spacing={1} alignItems={"center"}>
      <CheckCircle style={{ color: "#A29BFE" }} />
      <Typography variant="body2">{title}</Typography>
    </Stack>
  </Grid>
);

export default function HowItWork() {
  return (
    <Container
      id="howitworks"
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Stack
        sx={{ width: { xs: "100%", sm: "90%" }, alignItems: "center" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={4}
      >
        <Box>
          <img
            src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="doctor"
            style={{
              width: "450px",
              height: "auto",
              borderRadius: "10px",
            }}
          />
        </Box>
        <Stack spacing={3}>
          <Typography component="h2" variant="h5" color={"text.primary"}>
            Our mission is to revolutionize personalized healthcare through
            data-driven insights.
          </Typography>
          <Typography
            sx={{ lineHeight: "30px", fontSize: "14px", color: "grey.500" }}
          >
            By harnessing the power of advanced analytics, we aim to transform
            the healthcare landscape, providing tailored solutions that enhance
            outcomes, prevent diseases, and empower individuals to take control
            of their health and well-being.
          </Typography>
          <Grid container spacing={1}>
            {featuresList.map((feat, index) => (
              <Features key={index} title={feat} />
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Container>
  );
}
