import { Box, Typography } from "@mui/material";
import React from "react";
import MainLayout from "../components/layout/MainLayout";
import BlogHero from "../components/BlogHero";
import BlogContent from "../components/BlogContent";
import { useParams } from "react-router-dom";
import { blogsPost } from "../data";

export default function BlogPage() {

   let { articleId } = useParams();

  const article = blogsPost.find(blog => blog.id === articleId);
  
  return (
    <MainLayout>
      <BlogHero title={article?.title} descriptions={article?.descriptions} image={article?.image}  />
      <BlogContent body={article?.content} />
    </MainLayout>
  );
}
