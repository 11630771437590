import * as React from "react";
import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppAppBar from "../components/AppAppBar";
import Hero from "../components/Hero";
import LogoCollection from "../components/LogoCollection";
import Highlights from "../components/Highlights";
import Footer from "../components/Footer";
import getLPTheme from "../getLPTheme";
import HowItWork from "../components/HowItWork";
import Testimonials from "../components/Testimonials";
import Blog from "../components/Blog";

export default function LandingPage() {
  const [mode, setMode] = React.useState<PaletteMode>("light");

  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: "background.default" }}>
        <LogoCollection />
        <Highlights />
        <HowItWork />
        <Testimonials />
        <Blog />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
